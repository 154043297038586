import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import {
  Background, Container, Wrapper, Block, Logo, Header2, colors, mobileThresholdPixels,
}
  from '../components/styledComponents';
import LogoImg from '../assets/logo1-128.png';

const H1 = styled.h1`
  color: ${colors.navy};
  font-size: 48px;
  font-family: 'Roboto';
  font-weight: 300;
  margin-bottom: 20px;
  margin-top: 30px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 36px;
  }
`;

const TextLink = styled.div`
  color: ${colors.navy};
  text-decoration: none;
  margin: 20px;
  font-size: 20px;

  &:hover {
    transform: scale(1.1);
  }
`;

const Page404 = () => (
  <div>
    <Background />
    <Container>
      <Wrapper>
        <Block>
          <Header2>
            <Link to="/"><Logo src={LogoImg} /></Link>
            <H1>Oups, page introuvable</H1>
            <Link to="/"><TextLink>Revenir vers la page d'accueil</TextLink></Link>
          </Header2>
          <img src="https://media.giphy.com/media/ceTctNdypMCEE/giphy.gif" alt="cat sewing" />
        </Block>
      </Wrapper>
    </Container>
  </div>
);

export default Page404;
